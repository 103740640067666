import React, { useContext } from 'react'
import "./Navigation.css"
import Item from './Item'
import { Tag, NotificationsNone, PermIdentity, Twitter, HomeOutlined, EmailOutlined, BookmarkBorderOutlined, ListAltOutlined, PendingOutlined, MoreVert} from '@mui/icons-material'
import { Avatar, Button, Container, Popover } from '@mui/material'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import UserContext from '../../ContextUser'

export default function Navigation() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const poppy = open ? 'simple-popover' : undefined;


  const { user } = useContext(UserContext);

  return (
    <div className='navigation'>
      { user.username === null || user.username === undefined ? 
        <>
          <Link to={"/"} style={{ textDecoration: 'none', color: 'black' }}>
            <Twitter fontSize="large" className='twitter-btn'/>
          </Link>
          <Item Icon={Tag} text="Explore" />      
        </> :
        <>
          <Link to={"/"} style={{ textDecoration: 'none', color: 'black' }}>
            <Twitter fontSize="large" className='twitter-btn'/>
          </Link>
          <Item Icon={HomeOutlined} text="Home" />
          <Item Icon={Tag} text="Explore" />      
          <Item Icon={NotificationsNone} text="Notifications" />
          <Item Icon={EmailOutlined} text="Messages" />
          <Item Icon={BookmarkBorderOutlined} text="Bookmark"/>
          <Item Icon={ListAltOutlined} text="Lists" />
          <Item Icon={PermIdentity} text="Profile" />
          <Item Icon={PendingOutlined} text="More" />
          <Button variant="outlined" className='tweet-btn' fullWidth>Tweet</Button>
          <Button variant="outlined" aria-describedby={poppy} onClick={handleClick} className='user-btn' fullWidth>
            <Row className='align-items-center justify-content-start text-start'>
              <Col xs={3}>
                <Avatar alt="Twitter-Bird" src="default.png" className='user-btn-avatar' />
              </Col>
              <Col xs={7}>
                <Row>
                  <Col xs={12}>
                    <span className='user-btn-name'>{user.name}</span>
                  </Col>
                  <Col xs={12}>
                    <span className='user-btn-foot'>@{user.username}</span>
                  </Col>
                </Row>
              </Col>
              <Col xs={2}>
                <MoreVert fontSize='medium'/>
              </Col>
            </Row>
          </Button>
          <Popover
            id={poppy}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            disableScrollLock
            className="poppy"
          >
            <Container>
              <Link to={"/logout"} style={{ textDecoration: 'none', color: 'black' }}>
                Log out @{user.username}
              </Link>
            </Container>
          </Popover>
        </>  
      }
    </div>
  )
}
