// https://damp-wildwood-89473.herokuapp.com
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { useEffect, useState } from 'react';

// SIDEBARS
import Navigation from './Components/Navigation/Navigation';
import Widgets from './Components/Widgets/Widgets';

// PAGES
import Home from './Pages/Home';
import Profile from './Pages/Profile';
import Error from './Pages/Error';

// USER
import UserContext from './ContextUser';
import Logout from './Pages/Logout';
import Login from './Pages/Login';
import Registration from './Pages/Registration';

function App() {
  const [user, setUser] = useState({
    name: null,
    username: null
  })

  const unsetUser = () => {
    localStorage.clear()
    setUser({
      name: null,
      username: null
    })
  }

  useEffect(() => {
    const authorization = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
    fetch('https://twitter-api.xhunn.dev/users/verify', authorization)
      .then(user => {
        if (user.ok) {
          return user.json()
        } else {
          throw new Error("Error Occured")
        }
      })
      .then(result => {
        let user = result.content.username
        if (user !== null || user !== undefined || user !== 'undefined') {
          setUser({
            name: result.content.name,
            username: user
          })
        } else {
          setUser({
            name: null,
            username: null
          })
        }
    }).catch(error => console.error(error))
  }, [])

  return (
    <div className="app">
      <Router>
        <UserContext.Provider value={{ user, setUser, unsetUser }}>  
          {/* Navigation */}
          <Navigation />
        
          {/* Feeds */}
          <div className="feed">
            <Routes>
              <Route exact path='/' element={<Home/>}/>
              <Route exact path='/home' element={<Home/>}/>
              <Route exact path="/logout" element={<Logout/>} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/sign" element={<Registration />} />
              <Route path="*" element={<Error/>}/>
            </Routes>
          </div>

          {/* Widgets */}
          <Widgets/>
        </UserContext.Provider>
      </Router>
    </div>
  );
}

export default App;
