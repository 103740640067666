import React from 'react';
import { Link } from 'react-router-dom';
import "./items.css"

export default function Item({  text, Icon}) {
   return (
      <Link to={`/${text.toLowerCase()}`} style={{textDecoration: 'none', color: 'black'}}>
         <div className={`item`}>
            <Icon fontSize="medium"/>
            <h3>{text}</h3>
         </div>
      </Link>
  )
}
