import React, { useContext } from 'react'
import './pages.css'
import TweetBox from '../Components/TweetBox/TweetBox'
import Tweets from '../Components/Tweets/Tweets'
import UserContext from '../ContextUser'
import { useEffect, useState } from 'react'

export default function Home() {
   const { user } = useContext(UserContext);
   const [tweets, setTweets] = useState([])
   const [reloader, setReloader] = useState(0)

   useEffect(() => {
      fetch('https://twitter-api.xhunn.dev/tweets/')
         .then(result => {
            if (result.ok) {
               return result.json()
            } else {
               throw new Error("Error occurred")
            }
         }).then(data => {
            setReloader(0)
            setTweets(data.map(tweets => {
               return (
                  <>
                     <Tweets key={tweets._id} twtContent={tweets}/>
                  </>
               )
            }))
         })
   }, [reloader])
   
   return (
      <>
         <div className='feed-header'>
            <div className="feed-stick">
               <h4>Home</h4>
               {user.username === null || user.username === undefined ? 
                  <>
                  </> :
                  <>
                     <TweetBox props={{reloader: setReloader}}/>
                  </>
               }
            </div>
         </div>
         {tweets.reverse()}
     </>
  )
}
