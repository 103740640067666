import React, { useState } from 'react'
import { Button } from '@mui/material'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export default function Registration() {
  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [credentials, setCredentials] = useState('')
  const [password, setPassword] = useState('')

  const [day, setDay] = useState('01')
  const [month, setMonth] = useState('01')
  const [year, setYear] = useState('1940')
  
  const [preference, setPrefence] = useState('Email')
  const changePref = () => {
    if (preference === 'Email') {
      setPrefence('Phone')
    } else {
      setPrefence('Email')
    }
  }
  const navigate = useNavigate()
  
  const register = () => {
    let data
    if (preference === "Email") {
      data = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: name,
          username: username,
          password: password,
          dob: {
            year: year,
            day: day,
            month: month
          },
          email: credentials
        })
      }
    } else {
      data = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: name,
          username: username,
          password: password,
          dob: {
            year: year,
            day: day,
            month: month
          },
          Phone: credentials
        })
      }
    }

    fetch('https://twitter-api.xhunn.dev/users/register', data)
      .then(result => {
        if (result.ok) {
          return result.json()
        } else {
          throw new Error("Error occurred")
        }
      }).then(data => {
        navigate("/login")
      })
  }

  return (<div className='login'>
    <Container fluid className='mt-5 text-center'>
      <h2>SIGN-UP</h2>
    </Container>
    <Container fluid className='mt-5'>
      <Form.Control size="lg" type="text" value={name} onChange={value => setName(value.target.value)} placeholder="Name" />
      <br/>
      <Form.Control size="lg" type="text" value={username} onChange={value => setUsername(value.target.value)} placeholder="Username" />
      <br />
      <Form.Control size="lg" type="text" value={credentials} onChange={value => setCredentials(value.target.value)} placeholder={preference} />
      <Button className='my-2 trigger-btn' disableRipple onClick={changePref}>Change preference</Button>
      <br />
     <Form.Control size="lg" type="password" value={password} onChange={value => setPassword(value.target.value)} placeholder="Password" />
      <br />
      <Container fluid className='text-center mb-2 mt-3'>
        <Form.Label>Date of Birth</Form.Label>
      </Container>
      <Row className='justify-content-center'>
        <Col xs={3}>
          <Form.Select size="lg" onSelect={value => setMonth(value.target.value)}>
            <option>01</option>
            <option>02</option>
            <option>03</option>
            <option>04</option>
            <option>05</option>
            <option>06</option>
            <option>07</option>
            <option>08</option>
            <option>09</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
          </Form.Select>
        </Col>
        <Col xs={3}>
          <Form.Select size="lg" onSelect={value => setDay(value.target.value)}>
            <option>01</option>
            <option>02</option>
            <option>03</option>
            <option>04</option>
            <option>05</option>
            <option>06</option>
            <option>07</option>
            <option>08</option>
            <option>09</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
            <option>13</option>
            <option>14</option>
            <option>15</option>
            <option>16</option>
            <option>17</option>
            <option>18</option>
            <option>19</option>
            <option>20</option>
            <option>21</option>
            <option>22</option>
            <option>23</option>
            <option>24</option>
            <option>25</option>
            <option>26</option>
            <option>27</option>
            <option>28</option>
            <option>29</option>
            <option>30</option>
            <option>31</option>
          </Form.Select>
        </Col>
        <Col xs={4}>
          <Form.Select size="lg" onSelect={value => setYear(value.target.value)}>
            <option>1940</option>
            <option>1941</option>
            <option>1942</option>
            <option>1943</option>
            <option>1944</option>
            <option>1945</option>
            <option>1946</option>
            <option>1947</option>
            <option>1948</option>
            <option>1949</option>
            <option>1950</option>
            <option>1951</option>
            <option>1952</option>
            <option>1953</option>
            <option>1954</option>
            <option>1955</option>
            <option>1956</option>
            <option>1957</option>
            <option>1958</option>
            <option>1959</option>
            <option>1960</option>
            <option>1961</option>
            <option>1962</option>
            <option>1963</option>
            <option>1964</option>
            <option>1965</option>
            <option>1966</option>
            <option>1967</option>
            <option>1968</option>
            <option>1969</option>
            <option>1970</option>
            <option>1971</option>
            <option>1972</option>
            <option>1973</option>
            <option>1974</option>
            <option>1975</option>
            <option>1976</option>
            <option>1977</option>
            <option>1978</option>
            <option>1979</option>
            <option>1980</option>
            <option>1981</option>
            <option>1982</option>
            <option>1983</option>
            <option>1984</option>
            <option>1985</option>
            <option>1986</option>
            <option>1987</option>
            <option>1988</option>
            <option>1989</option>
            <option>1990</option>
            <option>1991</option>
            <option>1992</option>
            <option>1993</option>
            <option>1994</option>
            <option>1995</option>
            <option>1996</option>
            <option>1997</option>
            <option>1998</option>
            <option>1999</option>
            <option>2000</option>
            <option>2001</option>
            <option>2002</option>
            <option>2003</option>
            <option>2004</option>
            <option>2005</option>
            <option>2006</option>
            <option>2007</option>
            <option>2008</option>
            <option>2009</option>
            <option>2010</option>
          </Form.Select>
        </Col>
      </Row>
      <Button fullWidth className='page-btn mt-5' onClick={register}> SIGNUP </Button>
    </Container>
   </div>)
}
