import React, { useEffect, useState } from 'react'
import './tweet.css'

import { Container, Row, Col } from 'react-bootstrap';
import { CachedOutlined, ChatBubbleOutline, FavoriteBorderOutlined, IosShareOutlined } from '@mui/icons-material';
import { Avatar } from '@mui/material';

export default function Tweets({ twtContent }) {
   const { _id, owner, content, tweetedOn, isReply, likes, comment, retweetedBy } = twtContent;
   const [name, setName] = useState('')
   
   // NUMBERS
   const [likesCount, setLikeCount] = useState(likes.length)
   const [commentCount, setCommentCount] = useState(comment.length)
   const [retweetCount, setRetweetCount] = useState(retweetedBy.length)

   // TIME CALCULATOR
   const timeCalculator = () => {
      const timeThen = new Date(tweetedOn)
      const timeNow = new Date();

      let seconds = Math.floor((timeNow.getTime() - timeThen.getTime()) / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      if (days > 7) {
         return (<>
            {timeThen.getMonth() + 1} {timeThen.getDay()}, {timeThen.getFullYear()}
         </>)
      } else if (hours > 24) {
         return (<>
            {days}d
         </>)
      } else if (minutes > 60) {
         return (<>
            {hours}h
         </>)
      } else if (seconds > 60) {
         return (<>
            {minutes}m
         </>)
      } else {
         return (<>
            {seconds}s
         </>)
      }
   }

   // LIKE
   const liked = (event) => {
      event.preventDefault()
      let data = {
         method: "PUT",
         headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            id: _id
         })
      }
      fetch("https://twitter-api.xhunn.dev/tweets/like", data)
         .then(result => {
            if (result.ok) {
               return result.json()
            } else {
               throw new Error("Error occurred")
            }
         }).then(data => {
            setLikeCount(likesCount + 1)
         })
   }
   
   useEffect(() => {
      let searchData = {
         method: "POST",
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            username: owner
         })
      }
      fetch('https://twitter-api.xhunn.dev/users/details', searchData)
         .then(result => {
            if (result.ok) {
               return result.json()
            } else {
               throw new Error("Error occurred")
            }
         }).then(data => {
            setName(data.content.name)
         }).catch(err => {
            console.warn(err)
         })
   }, [])

   return (
      <Container fluid className="tweet">
         <Row className='align-items-start text-start'>
            <Col xs={1}>
               <Avatar alt="Twitter-Bird" src="default.png" />
            </Col>
            <Col xs={10}>
               <Row>
                  {/* Name */}
                  <Col xs={12}>
                     <span className='name-tweet'>{name}</span>
                     <span>  @{owner}  </span>
                     <span>·  {timeCalculator()}</span>
                  </Col>
                  {/* Content */}
                  <Col xs={12}>
                     <span>{content}</span>
                  </Col>
                  {/* Buttons */}
                  <Col xs={12} className="tweet-footer">
                     <Row>
                        <Col xs={3} className="comment tweetr-btn">
                           <ChatBubbleOutline />
                           {commentCount > 0 ? 
                              <>
                                 <span className='counters'>{commentCount}</span>
                              </> : <></>
                           }
                        </Col>
                        <Col xs={3} className="retweet tweetr-btn">
                           <CachedOutlined />
                           {retweetCount > 0 ? 
                              <>
                                 <span className='counters'>{retweetCount}</span>
                              </> : <></>
                           }
                        </Col>
                        <Col xs={3} className="like tweetr-btn" onClick={e => liked(e)}>
                           <FavoriteBorderOutlined />
                           {likesCount > 0 ? 
                              <>
                                 <span className='counters'>{likesCount}</span>
                              </> : <></>
                           }
                        </Col>
                        <Col xs={3} className="share tweetr-btn">
                           <IosShareOutlined />
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Col>
         </Row>
      </Container>
   )
}
