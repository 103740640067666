import { Button } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Container, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import UserContext from '../ContextUser'

export default function Login() {
   const [credentials, setCredentials] = useState('')
   const [password, setPassword] = useState('')
   const { setUser } = useContext(UserContext)
   const navigate = useNavigate()

   const login = () => {
      let data = {
         method: "POST",
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({      
            credentials: credentials.toLowerCase(),
            password: password
         })
      }
      fetch('https://twitter-api.xhunn.dev/users/login', data)
         .then(result => {
            if (result.ok) {
               return result.json()
            } else {
               throw new Error("Error occurred")
            }
         }).then(data => {
            if (typeof data.accessToken !== 'undefined') {
               localStorage.setItem('token', data.accessToken);
               loginDetails(data.accessToken)
            }
         })
   }

   const loginDetails = token => {
      const authorization = {
         method: "GET",
         headers: {
            Authorization: `Bearer ${token}`
         }
      }
      fetch('https://twitter-api.xhunn.dev/users/verify', authorization)
         .then(result => {
            if (result.ok) {
               return result.json()
            } else {
               throw new Error("Error occurred")
            }
         }).then(data => {
            setUser({
               name: data.name,
               username: data.username
            })
            navigate('/home')
            window.location.reload();
         })
   }

   return (<div className='login'>
      <Container fluid className='mt-5 text-center'>
         <h2>LOGIN</h2>
      </Container>
      <Container fluid className='mt-5'>
         <Form.Control size="lg" type="text" value={credentials} onChange={value => setCredentials(value.target.value)} placeholder="Email | User | Phone" />
         <br />
         <Form.Control size="lg" type="password" value={password} onChange={value => setPassword(value.target.value)} placeholder="Password" />
         <Button fullWidth className='page-btn mt-5' onClick={login}> LOGIN </Button>
      </Container>
   </div>)
}

