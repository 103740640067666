import { useContext, useEffect } from "react";
import UserContext from "../ContextUser";
import { useNavigate } from 'react-router-dom';

const Logout = () => {
   const navigate = useNavigate()
   const { unsetUser, setUser } = useContext(UserContext)
   
   unsetUser();
   useEffect(() => {
      setUser({
         name: null,
         username: null
      })
   })

   return (<>
      {navigate("/home")}
   </>)
}

export default Logout;