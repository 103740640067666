import React from 'react'

export default function Error() {
  return (
     <div className='error'>
        <h1>Page is yet to be re-created</h1>
        <img src="building.gif" alt="building!!!"/>
     </div>
  )
}
