import React, { useContext } from 'react'
import { Card, Container } from 'react-bootstrap'
import { Button } from '@mui/material'
import UserContext from '../../ContextUser'
import './widget.css'
import { useNavigate } from 'react-router-dom'

export default function Widgets() {
  const navigate = useNavigate()
  const { user } = useContext(UserContext)

  const signup = () => {
    navigate('/sign')
  }
  const login = () => {
    navigate('/login')
  }

  return (
    <div className='widgets'>
      {user.username === null || user.username === undefined ? 
        <>
          <Container fluid className='mt-5'>
            <Button className='widget-btn' fullWidth onClick={signup}>Sign-up</Button>
            <Button className='widget-btn' fullWidth onClick={login}>Login</Button>
          </Container>
        </> :
        <></>
       }
        <Container fluid className='mt-5'>
          <Card>
            <Card.Header className='display-6'>Twitter Clone</Card.Header>
            <Card.Body>
              <blockquote className="blockquote mb-0">
                <h3 className='header-card'>
                  Functionalities:
                </h3>
                <ul className='lead'>
                  <li>Tweet</li>
                  <li>Like</li>
                </ul>
                <footer className="blockquote-footer">
                  Yours truly, <cite title="Source Title">Xhunn</cite>
                </footer>
              </blockquote>
            </Card.Body>
          </Card>
        </Container>
    </div>
  )
}
