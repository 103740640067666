import './TweetBox.css'
import React, { useState } from 'react'
import { Avatar, Button } from '@mui/material'
import { Form } from 'react-bootstrap'

export default function TweetBox({ props }) {
   const [content, setContent] = useState('')
   const { reloader } = props
   const tweet = (event) => {
      console.log(typeof reloader)
      console.log(reloader.setReloader)
      event.preventDefault()
      let data = {
         method: "POST",
         headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            tweet: content
         })
      }
      fetch('https://twitter-api.xhunn.dev/tweets/tweet', data)
         .then(result => {
            if (result.ok) {
               return result.json()
            } else {
               throw new Error("Error occurred")
            }
         }).then(data => {
            setContent('')
            reloader(1)
         })
   }

   return (
      <>
         <div className="tweet-box">
            <Avatar alt="Twitter-Bird" src="default.png" />
            <Form.Control size="lg" type="text" placeholder="What's Happening?" value={content} onChange={value => setContent(value.target.value)} className='text-box'/>
         </div>
         <hr/>
         <div className='footer'>
            <Button variant="outlined" className='tweet-btn' onClick={e => {
               tweet(e)
            }}>Tweet</Button>
         </div>
      </>
   )
}
